import { PageProps } from "gatsby";
import React from "react";

import { Learn } from "../components/pages/Learn";

import { snProps } from "../js/utils";

const LearnPage = (props: PageProps) => <Learn {...{ ...snProps, ...props }} />;

export default LearnPage;
